@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&family=Dancing+Script:wght@400;500;600;700&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap");
body {
  scroll-behavior: smooth;
  background-color: black;
  color: white;
  margin: 0;
  font-family: "Roboto Slab", serif;
}
.video-container video::-webkit-media-controls {
  display: none !important;
}

.video-container video::-webkit-media-controls-enclosure {
  display: none !important;
}
